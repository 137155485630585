<template>
  <div>
    <NuxtPicture
      v-if="!isVideoMedia"
      loading="lazy"
      :img-attrs="imgAttrs"
      :alt="alt"
      :src="url"
    />
    <video
      v-else
      controls
      autoplay
    >
      <source
        :src="url"
        type="video/mp4"
      >
    </video>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  src: string,
  alt?: string,
  imgAttrs?: { class: string; }
}>();

const img = useImage();

const url = img(props.src);

const isVideoMedia = computed(() => {
  return (
    props.src.endsWith('.mp4') ||
    props.src.endsWith('.mov') ||
    props.src.endsWith('.webm')
  );
});
</script>
